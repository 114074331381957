import './App.css';

import { Row, Col } from 'react-bootstrap';

function App() {
  return (
    <div>
      <div className='mb-3'>
        Media Geek 🤳
      </div>
    </div>
  );
}

export default App;
